
.black-text {
    color: #12141D;
}

.section-padding {
    padding: 1% 10%
}

.section-padding-mobile {
    padding: 1% 6%
}

.checkout-padding {
    padding: 5% 10%
}

.nav-title {
    font-weight: 700;
    font-size: 24px;
}

.splash-head {
    font-size: 46px;
    font-weight: 700;
    font-family: 'Arvo', serif;
}

.splash-head-mobile {
    font-size: 32px;
    font-weight: 700;
    font-family: 'Arvo', serif;
}


.splash-head-lg {
    font-size: 62px;
    font-weight: 700;
    font-family: 'Arvo', serif;
}

.splash-head-lg-mobile {
    font-size: 44px;
    font-weight: 700;
    font-family: 'Arvo', serif;
}

.breakline {
    width: 250px;
    height: 1px;
    background-color: #00000020;
}

a {
    text-decoration: none;
    color:#3078F6;
}


.breakline-mobile {
    width: 100px;
    height: 1px;
    background-color: #00000020;
}

.feature-text {
    font-size: 16px;
    font-family: 'Arvo', serif;
    color: #00000060;
}

.feature-text-mobile {
    font-size: 12px;
    font-family: 'Arvo', serif;
    color: #00000060;
}

.feature-card {
    background-color: #00000020;
}

.big-head {
    font-size: 56px !important;
    font-weight: 700 !important;
}

.big-stat {
    font-size: 32px;
    font-weight: 500;
}

.medium2 {
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 17px !important;
}

.headline {
    font-size: 20px !important;
    font-weight: 700 !important;
    line-height: 29px !important;
}

.headline-mobile {
    font-size: 16px !important;
    font-weight: 700 !important;
}

.headline2-arvo {
    font-size: 24px !important;
    font-weight: 700 !important;
    line-height: 29px !important;
    font-family: 'Arvo', serif;
}

.headline2-arvo-mobile {
    font-size: 16px !important;
    font-weight: 700 !important;
    line-height: 29px !important;
    font-family: 'Arvo', serif;
}

.feature-label {
    font-size: 10px !important;
    font-weight: 700 !important;
    line-height: 29px !important;
}

.feature-label-mobile {
    font-size: 9px !important;
    font-weight: 700 !important;
}


.feature {
    font-size: 20px;
    font-weight: 700;
    line-height: 29px;
}

.feature-mobile {
    font-size: 15px;
    font-weight: 700;
}

.title-emphasized {
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 20px !important;
}

.body1 {
    font-size: 14px !important;
    line-height: 17px !important;
}

.body1-mobile {
    font-size: 12px !important;
    line-height: 14px !important;
}

.body1-medium {
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 17px !important;
}

.body1-emphasized {
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: 17px !important;
}

.body2 {
    font-size: 12px !important;
    line-height: 15px !important;
}

.body2-medium {
    font-size: 12px !important;
    font-weight: 500 !important;
    line-height: 15px !important;
}

.body2-emphasized {
    font-size: 12px !important;
    font-weight: 600 !important;
    line-height: 15px !important;
}

.body2-subheader {
    font-size: 12px !important;
    font-weight: 600 !important;
    letter-spacing: 0.5px !important;
    line-height: 15px !important;
}

.body3 {
    font-size: 11px !important;
    line-height: 13px !important;
}

.body3-medium {
    font-size: 11px !important;
    font-weight: 500 !important;
    line-height: 13px !important;
}

.body3-emphasized {
    font-size: 11px ;
    font-weight: 600 !important;
    line-height: 13px !important;
}

.body3-subheader {
    font-size: 11px !important;
    font-weight: 600 !important;
    letter-spacing: 0.5px !important;
    line-height: 13px !important;
}

.small-indicator {
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 0px;
}

.small-headline {
    font-size: 22px;
    font-weight: 600;
}

.small-headline-mobile {
    font-size: 18px;
    font-weight: 600;
}

.large-headline {
    font-size: 28px;
    font-weight: 600;
}

.large-headline-mobile {
    font-size: 24px;
    font-weight: 600;
}

.uppercase {
    text-transform: uppercase;
}

.o9 {
    opacity: .9;
}

.o8 {
    opacity: .8;
}

.o6 {
    opacity: .6;
}

.o5 {
    opacity: .5;
}

.sale-section-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-top: 1px solid #00000040;
    border-bottom: 1px solid #00000040;
}

.sale-section-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-bottom: 1px solid black;
}

.pointer {
    cursor: pointer;
    transition: var(--transition1);
}

.pointer-hvr {
    cursor: pointer;
    transform: translateY(-4px);
}
