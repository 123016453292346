:root {
    --bodyColor: #12141D;
    --white: #FFFFFF;
    --aqua-gradient: linear-gradient(to right, #3078F6, #36ABFF);
    --pink-gradient: linear-gradient(to right, #EAC1DA, #D6C3BC);
    --red-gradient: linear-gradient(to right, #ed0e0e, #e03a3a);
    --red: #ed0e0e;
    --black: #000000;
}

.red {
    color: var(--red);
}

.black {
    color: var(--black);
}

body {
    background-image: var(--aqua-gradient);
    color: var(--white);
    font-family: 'Inter', sans-serif;
    animation-name: fade-bg-1;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-delay: 0ms;
}

@keyframes fade-bg-1 {
    from {
        opacity: 1;
        background-color: red;
    }

    50% {
        opacity: 1;
        background-color: green;
    }

    to {
        opacity: 1;
        background-color: pink;
    }

}

.red-button {
    border: none;
    outline: none;
    padding: var(--space20) var(--space24);
    border-radius: var(--space16);
    color: var(--white);
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;
    background-color: var(--red);
}

.red-button-mobile {
    border: none;
    outline: none;
    padding: var(--space8) var(--space12);
    border-radius: var(--space12);
    color: var(--white);
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    background-color: var(--red);
}

.blue-button {
    border: none;
    outline: none;
    background-image: var(--aqua-gradient);
    padding: var(--space20) var(--space24);
    border-radius: var(--space16);
    color: var(--white);
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;
}

.grey-button {
    border: none;
    outline: none;
    background-color: #FFFFFF09;
    padding: var(--space16) var(--space20);
    border-radius: var(--space16);
    color: var(--white);
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;
    opacity: .8
}

button {
    transition: 150ms ease;
    box-shadow: 0 0px 8px #00000020;
    font-family: 'Arvo', serif;
    text-transform: uppercase;
}

button:hover {
    transform: translateY(-4px);
    box-shadow: 0 4px 8px #00000020;
}

ul {
    margin: 0;
    padding: 0;
}

li {
    margin: 0;
    padding: 0;
    list-style: none;
}

.nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 5%;
    position: fixed;
    left: 0;
    right: 0;
    z-index: 99999;
    transition: all 100ms ease;
    backdrop-filter: blur(4px);
    background-color: #3078F6;
}

.nav ul {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.nav ul li {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80px;
    position: relative;
    padding: 0px 32px;
    background-color: transparent;
    transition: 150ms ease;
    cursor: pointer;
    border-bottom: 3px solid transparent;
    color: #FFFFFF50
}

.nav ul li:hover {
    background-color: #FFFFFF06;
    border-bottom: 3px solid #FFFFFF50;
    color: #FFFFFF
}

.nav-border {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 4px 4px 0px 0px;
    background-image: var(--aqua-gradient);
    transition: 150ms ease;
}

.splash-card {
    background-color: #FFFFFF10;
    border-radius: var(--space16);
    padding: var(--space32);
    margin: var(--space20);
    box-shadow: 0 0 8px #00000020;
    transition: 150ms ease;
}

.splash-card:hover {
    box-shadow: 0 4px 8px #00000020;
    transform: translateY(-4px);
}

.phones {
    transform: scaleX(-1);
}

.plan-card {
    background-color: #FFFFFF10;
    border-radius: var(--space16);
    padding: var(--space32);
    margin: var(--space20);
    box-shadow: 0 0 8px #00000020;
    transition: 150ms ease;
}

.sale-section {
    /*background-image: var(--red-gradient);*/
    background-color: #FFFFFF;
}

.toggle-button {
    padding: var(--space8) var(--space20);
    margin: 0 var(--space32);
    text-transform: uppercase;
    font-weight: 600;
    font-family: 'Arvo', serif;
    cursor: pointer;
    border-bottom: 4px solid white;
    transition: 150ms ease;
}

.toggle-button-inactive {
    padding: var(--space8) var(--space20);
    margin: 0 var(--space32);
    text-transform: uppercase;
    font-weight: 600;
    font-family: 'Arvo', serif;
    cursor: pointer;
    opacity: .6;
    border-bottom: 4px solid transparent;
    transition: 150ms ease;
}

.toggle-button-inactive:hover {
    border-bottom: 4px solid #FFFFFF50;
}

.marquee {
    background-image: var(--red-gradient);
}

.sale-banner {
    background-image: var(--aqua-gradient);
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: var(--space48) 0px;
    flex: 1;
    justify-content: flex-end;
    border-bottom-right-radius: 200px;
    border-top-right-radius: 200px;
    color: white
}

.thumbnail {
    border: 2px solid #00000030;
    width: 120px;
    border-radius: var(--space4);
    cursor: pointer;
    transition: var(--transition1);
}

.thumbnail-mobile {
    border: 2px solid #00000030;
    width: 60px;
    border-radius: var(--space4);
    cursor: pointer;
    transition: var(--transition1);
}

.thumbnail:hover {
    border: 2px solid #3078F6;
}

.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #00000080;
    z-index: 9999999;
}

.modal-body {
    background-image: var(--aqua-gradient);
    border-radius: var(--space16);
    padding: var(--space16) var(--space32) var(--space32) var(--space32)
}

.modal-body-mobile {
    background-image: var(--aqua-gradient);
    border-radius: var(--space16);
    padding: var(--space8) var(--space4) var(--space4) var(--space4);
}

.contract-container {
    background-color: #00000010;
    border-radius: 6px;
    border:1px solid #00000030;
    padding:8px 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
